import React from "react"
import styled from "styled-components"
import { Col as RfgCol, ColProps } from "react-flexbox-grid"

interface Props extends ColProps {
  style?: React.CSSProperties
}

const Component: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <RfgCol {...rest} style={{ padding: 0 }}>
      {children}
    </RfgCol>
  )
}

const Column = styled(Component)`
  display: flex;
  flex: 0 1 100%;
  flex-direction: column;
  width: 100%;
`

export default Column
