import React from "react"
import styled from "styled-components"
import { Grid, GridProps } from "react-flexbox-grid"

interface Props extends GridProps {
  style?: React.CSSProperties
}

const Container: React.FC<Props> = ({ children, ...rest }) => {
  return <SContainer {...rest}>{children}</SContainer>
}

export default Container

const SContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding: 16px;
  width: 100%;
  position: relative;

  > * {
    max-width: 100%;
    width: 100%;
  }
`
