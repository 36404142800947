//@ts-nocheck

import Chevron from "../../assets/svg/icons/chevron_down.svg"
import Marker from "../../assets/svg/icons/marker.svg"
import LinkedIn from "../../assets/svg/icons/icons8-linkedin.svg"
import Instagram from "../../assets/svg/icons/icons8-instagram.svg"
import Facebook from "../../assets/svg/icons/icons8-facebook.svg"
import Xing from "../../assets/svg/icons/icons8-xing_squared.svg"
import ArrowUp from "../../assets/svg/icons/icons8-collapse_arrow.svg"
import Close from "../../assets/svg/icons/icons8-delete.svg"
import Plus from "../../assets/svg/icons/plus.svg"
import Minus from "../../assets/svg/icons/minus.svg"
import Phone from "../../assets/svg/icons/phone.svg"
import Time from "../../assets/svg/icons/time.svg"
import Hamburger from "../../assets/svg/icons/icons8-menu.svg"
import Checked from "../../assets/svg/icons/checkmark.svg"
import Truck from "../../assets/svg/icons/truck.svg"
import Car from "../../assets/svg/icons/car.svg"
import Factory from "../../assets/svg/icons/factory.svg"
import Electronics from "../../assets/svg/icons/electronics.svg"
import Hospital from "../../assets/svg/icons/hospital.svg"
import Dining from "../../assets/svg/icons/dining.svg"
import NextSection from "../../assets/svg/icons/next-section.svg"
import Hear from "../../assets/svg/icons/hear.svg"
import Think from "../../assets/svg/icons/think.svg"
import Build from "../../assets/svg/icons/build.svg"
import Repeat from "../../assets/svg/icons/repeat.svg"
import Quote from "../../assets/svg/icons/quote.svg"
import WineGlass from "../../assets/svg/icons/wineGlass.svg"
import Snacks from "../../assets/svg/icons/snacks.svg"
import Elder from "../../assets/svg/icons/elder.svg"
import Card from "../../assets/svg/icons/card.svg"
import Medic from "../../assets/svg/icons/medic.svg"
import Project from "../../assets/svg/icons/project.svg"
import Career from "../../assets/svg/icons/career.svg"
import HomeOffice from "../../assets/svg/icons/homeOffice.svg"
import OpenCulture from "../../assets/svg/icons/openCulture.svg"
import ArrowRight from "../../assets/svg/icons/arrow-right.svg"
import CirclesDecor from "../../assets/svg/icons/circlesDecor.svg"
import Code from "../../assets/svg/icons/code.svg"
import Sun from "../../assets/svg/icons/sun.svg"
import Moon from "../../assets/svg/icons/moon.svg"
import IndustrySectors from "../../assets/svg/icons/industrySectors.svg"
import Services from "../../assets/svg/icons/services.svg"
import Benefits from "../../assets/svg/icons/benefits.svg"
import DataAnalysis from "../../assets/svg/icons/dataAnalysis.svg"
import Mechanism from "../../assets/svg/icons/mechanism.svg"
import StudyInfo from "../../assets/svg/icons/studyInfo.svg"
import PulledPlug from "../../assets/svg/icons/pulledPlug.svg"
import Mobile from "../../assets/svg/icons/mobile.svg"
import Architecture from "../../assets/svg/icons/architecture.svg"
import Web from "../../assets/svg/icons/web.svg"
import Integration from "../../assets/svg/icons/integration.svg"
import HybridSolution from "../../assets/svg/icons/hybridSolution.svg"
import CloudMigration from "../../assets/svg/icons/cloudMigration.svg"
import CloudArchitecture from "../../assets/svg/icons/cloudArchitecture.svg"
import ConIntegration from "../../assets/svg/icons/continuousMode.svg"
import Infrastucture from "../../assets/svg/icons/infrastructure.svg"
import Monitoring from "../../assets/svg/icons/monitoring.svg"
import DesignConcept from "../../assets/svg/icons/designConcept.svg"
import ProjectManagement from "../../assets/svg/icons/projectManagement.svg"
import Requirement from "../../assets/svg/icons/requirement.svg"
import Reduction from "../../assets/svg/icons/reduction.svg"
import Efficient from "../../assets/svg/icons/efficient.svg"
import QuoteLeft from "../../assets/svg/icons/quote-left.svg"
import { ReactSVGElement } from "react"

const Icon: { [key: string]: ReactElement } = {
  ArrowUp,
  Chevron,
  QuoteLeft,
  Checked,
  Close,
  Hamburger,
  Marker,
  LinkedIn,
  Instagram,
  Facebook,
  Xing,
  Truck,
  Car,
  Factory,
  Electronics,
  Hospital,
  Dining,
  NextSection,
  Hear,
  Build,
  Repeat,
  Think,
  Quote,
  WineGlass,
  Snacks,
  Elder,
  Card,
  Medic,
  Project,
  Career,
  HomeOffice,
  OpenCulture,
  Plus,
  Minus,
  Time,
  Phone,
  ArrowRight,
  CirclesDecor,
  Code,
  Sun,
  Moon,
  IndustrySectors,
  Services,
  Benefits,
  Mechanism,
  StudyInfo,
  DataAnalysis,
  PulledPlug,
  Mobile,
  Architecture,
  Web,
  Integration,
  HybridSolution,
  CloudArchitecture,
  CloudMigration,
  Infrastucture,
  Monitoring,
  ConIntegration,
  ProjectManagement,
  Requirement,
  DesignConcept,
  Efficient,
  Reduction,
}

export default Icon
