import React, { FunctionComponent } from "react"
import styled from "styled-components"

const Button: FunctionComponent<ButtonType> = ({
  children,
  onClick,
  isBlock,
  style,
}) => {
  return (
    <PureButton onClick={onClick} isBlock={isBlock} style={style}>
      {children}
    </PureButton>
  )
}

type ButtonType = {
  isBlock?: boolean
  style?: React.CSSProperties
} & React.HTMLProps<HTMLButtonElement>

const PureButton = styled.button<any>`
  border-radius: 50px;
  background: ${props => props.theme.palette.blue.vibrant};
  border: 2px solid ${props => props.theme.palette.blue.vibrant};
  line-height: 1;
  color: white;
  font-weight: bold;
  font-size: 0.875rem;
  align-self: flex-start;
  text-align: center;
  padding: 16px 40px;
  outline: 0;
  width: ${props => (props.isBlock ? "100%" : "auto")};
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  &:hover {
    background: #323f4c;
    border: 2px solid #323f4c;
  }
`

export default Button
