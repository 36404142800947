import styled from "styled-components"
import React from "react"

const Emphasized: React.FC<{}> = ({ children }) => {
  return <Text>{children}</Text>
}

const Text = styled.span`
  color: ${props => props.theme.palette.blue.vibrant};
`
export default Emphasized
