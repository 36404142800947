import React from "react"
import { Row as RfgRow, RowProps } from "react-flexbox-grid"
import styled from "styled-components"

interface Props extends RowProps {}

const Row: React.FC<Props> = ({ children, style, ...rest }) => {
  return (
    <StyledRow {...rest} style={{ margin: 0, ...style }}>
      {children}
    </StyledRow>
  )
}

const StyledRow = styled(RfgRow)`
  display: flex;
  flex: 0 1 100%;
  flex-direction: row;
  height: auto;
`

export default Row
