import React, { FC, ReactElement } from "react"
import styled from "styled-components"
import { Container } from "../components"

const SvgLayer: FC<SvgLayerProps> = ({ icon, inverted, children }) => {
  return (
    <Container>
      <FlexRow inverted={inverted}>
        <SvgContainer>{icon()}</SvgContainer>
        <Content inverted={inverted}>{children}</Content>
      </FlexRow>
    </Container>
  )
}

type SvgLayerProps = {
  icon: () => ReactElement
  inverted?: boolean
}

export default SvgLayer

const FlexRow = styled.div<{
  inverted?: boolean
}>`
  display: flex;
  ${p => p.theme.breakpoints.lg} {
    flex-direction: column;
  }

  width: 100%;
  align-items: center;
  flex-direction: ${props => (props.inverted ? "row-reverse" : "row")};
`
const SvgContainer = styled.div`
  > svg {
    height: 500px !important;
    width: 500px !important;

    ${p => p.theme.breakpoints.sm} {
      width: 350px !important;
      height: 350px !important;
    }
  }
`

const Content = styled.div<{
  inverted?: boolean
}>`
  width: 100%;
  height: 100%;
  padding-left: ${props => (!props.inverted ? "60px" : null)};
  padding-right: ${props => (props.inverted ? "60px" : null)};
  max-width: 700px;

  ${p => p.theme.breakpoints.lg} {
    padding: 0;
  }
`
