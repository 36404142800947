import { getImage, GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

export const Avatar: React.FC<{
  image: IGatsbyImageData
  name: string
  position: string
}> = props => {
  return (
    <AvatarWrapper>
      <AvatarImage alt={props.name} image={getImage(props.image)!} />
      <AvatarInformation>
        <AvatarName>{props.name}</AvatarName>
        <AvatarPosition>{props.position}</AvatarPosition>
      </AvatarInformation>
    </AvatarWrapper>
  )
}

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const AvatarInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const AvatarName = styled.span`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: white;
`

const AvatarPosition = styled.span`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25;
  color: white;
`

const AvatarImage = styled(GatsbyImage)`
  flex: 0 0 auto;
  border-radius: 58px;
  box-sizing: border-box;
  border: 4px solid white;
  margin-right: 24px;
`

export default Avatar
