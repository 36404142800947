import React, { FunctionComponent } from "react"
import styled from "styled-components"

const SecondarySection: FunctionComponent<SectionProps> = ({
  children,
  partially,
  withArticle,
}) => {
  return (
    <SectionWrapper
      className="secondary-section"
      withArticle={withArticle}
      partially={partially}
    >
      {children}
    </SectionWrapper>
  )
}

type SectionProps = {
  partially?: boolean
  withArticle?: boolean
  children: React.ReactNode
}

export default SecondarySection

const SectionWrapper = styled.section<any>`
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  padding-top: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  margin-top: -1px;
  width: 100%;
  padding: 0;
  top: ${props => (props.partially ? "-400px" : "")};
  top: ${props => (props.withArticle ? "150px" : null)};
  margin-bottom: ${props => (props.partially ? "-400px" : "0")};
`
