import React from "react"
import styled from "styled-components/macro"
import CountUp from "react-countup"

interface Props {
  radius?: number
  stroke?: number
  displayedValue: number
}

const ProgressCircle: React.FC<Props> = ({
  radius = 80,
  stroke = 4,
  displayedValue,
}) => {
  const normalizedRadius = radius - stroke * 2
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffest = 0

  return (
    <FlexContainer radius={radius}>
      <CircleContainer>
        <P>
          <CountUp duration={1.5} end={displayedValue} suffix="+" />
        </P>
        <Circle height={radius * 2} width={radius * 2}>
          <circle
            strokeWidth={stroke}
            strokeDasharray={circumference + " " + circumference}
            style={{ strokeDashoffset: strokeDashoffest }}
            stroke-width={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          ></circle>
        </Circle>
      </CircleContainer>
    </FlexContainer>
  )
}

export default ProgressCircle

const FlexContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.palette.backgroundColor};
  border-radius: ${props => props.radius * 2}px;
  padding: 1rem;
  /* box-shadow: 0 4px 30px rgb(11 33 74 / 11%), 0 -2px 5px rgb(11 33 74 / 3%); */
  margin-bottom: 24px;
`

const CircleContainer = styled.div`
  position: relative;
`
const Circle = styled.svg`
  display: flex;
  position: relative;
  line-height: 0;
  circle {
    fill: transparent;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: ${props => props.theme.palette.borderColor};
  }
`

const P = styled.p`
  position: absolute;
  margin-bottom: -5px;
  font-weight: 400;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.25rem;
  color: ${props => props.theme.palette.text.vibrant};
  z-index: 10;
  padding: 0;
  margin: 0;
`
