import styled from "styled-components"

const Spacer = styled.div<any>`
  height: ${props => props.gutter}px;
  background: none;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 100%;
`

export default Spacer
