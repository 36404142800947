import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import Container from "../Container/Container"

type HeroSectionProps = {
  style?: React.CSSProperties
}

const HeroSection: FunctionComponent<HeroSectionProps> = ({
  children,
  style,
}) => {
  return (
    <Container>
      <HeroSectionWrapper className={"hero-section"} style={style}>
        {children}
      </HeroSectionWrapper>
    </Container>
  )
}

export default HeroSection

interface HeroSectionWrapperProps extends HeroSectionProps {}

const HeroSectionWrapper = styled.section<HeroSectionWrapperProps>`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    width: 100%;
  }

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: relative;
  padding-top: 10%;
  width: 100%;
  height: calc(100vh - 88px);

  ${props => css`
    ${props.theme.breakpoints.md} {
      h1 {
        font-size: 2rem;
      }
    }
  `}

  ${props => props.theme.breakpoints.md} {
    height: auto;
  }
`
