import { useLottie } from "lottie-react"
import React from "react"
import { FC } from "react"
import styled from "styled-components"

const Lottie: FC<LottieProps> = ({ lottie, loop, style }) => {
  const options = {
    animationData: lottie,
    loop: loop,
    autoplay: true,
  }

  const { View } = useLottie(options)

  return <Wrapper style={style}>{View}</Wrapper>
}

type LottieProps = {
  lottie: any
  loop: boolean
  width?: number
  height?: number
  style?: React.CSSProperties
}

export default Lottie

const Wrapper = styled.div<{
  width?: number
  height?: number
}>`
  width: ${props => (props.width ? `${props.width}px` : "100%")};
  height: ${props => (props.height ? `${props.height}px` : "100%")};
  /* height: 100%;
  width: 100%; */
`
