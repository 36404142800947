import { motion } from "framer-motion"
import React from "react"
import styled, { css } from "styled-components"
import Card, { CardProps } from "../Card/Card"

interface Props {
  entries: CardProps[]
}

const CardList: React.FC<Props> = ({ entries }) => {
  return (
    <CardsListWrapper amount={entries.length}>
      {entries.map(entry => {
        return <Card key={entry.title} {...entry} />
      })}
    </CardsListWrapper>
  )
}

export default CardList

const CardsListWrapper = styled(motion.ul)<{ amount: number }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  > div {
    width: 30%;
  }

  ${p => css`
    ${p.theme.breakpoints.xl} {
      > div {
        width: 48%;
      }
    }

    ${p.theme.breakpoints.md} {
      > div {
        width: 100%;
      }
    }
  `}

  gap: 1.5rem;
  max-width: 1280px;
  padding: 0;
`
