import React, { FC } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import {
  Row,
  Column,
  Spacer,
  Paragraph,
  Subtitle,
  Heading,
} from "../components"
import { IParagraph } from "./types"

const Article: FC<ArticleProps> = ({ article, image }) => {
  return (
    <NegativeMargin>
      {article.map((item, i) => {
        return (
          <>
            <Row>
              <Column>
                {item.subtitle ? (
                  <Subtitle color="blue">Chancen</Subtitle>
                ) : null}
                <HeadingWrapper>
                  {item.subtitle ? null : <LeftLineWrapper />}

                  <Heading
                    tag={i === 0 ? "h1" : "h2"}
                    //@ts-ignore
                    //style={{ paddingBottom: "0px", color: "white" }}
                  >
                    {item.heading}
                  </Heading>
                </HeadingWrapper>
              </Column>
            </Row>
            {item.paragraphs.map((paragraph, i) => {
              const endOfIndex: number = item.paragraphs.length - 1
              return (
                <>
                  <Row>
                    <Column>
                      <Paragraph>{paragraph}</Paragraph>
                    </Column>
                  </Row>
                  <Spacer gutter={16} />
                  {item.image ? (
                    <>
                      {i === endOfIndex ? (
                        <>
                          <Row>
                            <Column>
                              <BlogImageWrapper layoutId={item.image}>
                                <BlogImage
                                  image={getImage(
                                    image as unknown as IGatsbyImageData
                                  )}
                                />
                              </BlogImageWrapper>
                            </Column>
                          </Row>
                          <Spacer gutter={48} />
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              )
            })}
          </>
        )
      })}
      <Spacer gutter={80} />
    </NegativeMargin>
  )
}

type ArticleProps = {
  article: IParagraph[]
  image: string
}

export default Article

const NegativeMargin = styled.div`
  margin-top: -90px;
`

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
`

const LeftLineWrapper = styled.div`
  width: 5px;
  height: 110%;
  background-color: #2188f9;
  margin-right: 24px;
`

const BlogImageWrapper = styled(motion.div)`
  border-radius: 8px;
  width: 100%;
  height: 500px;
`

const BlogImage = styled(GatsbyImage)<any>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`
