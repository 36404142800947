export type ThemeName = "dark" | "light"

export class ThemePreference {
  private storageKey: string = "themeSetting"
  private prefersDarkThemeMq: MediaQueryList | null = null
  private subscriberCallback: ((theme: ThemeName) => any) | null = null

  constructor() {
    if (typeof window != "undefined") {
      this.prefersDarkThemeMq = window.matchMedia(
        "(prefers-color-scheme: dark)"
      )
    }
  }

  private getStorage = (): ThemeName | null => {
    return localStorage.getItem(this.storageKey) as any
  }

  private prefersDarkThemeMqChangeHandler = (ev: MediaQueryListEvent) => {
    this.setStorage(ev.matches ? "dark" : "light")
  }

  setStorage = (theme: ThemeName) => {
    localStorage.setItem(this.storageKey, theme)
    this.subscriberCallback?.(theme)
  }

  get = (): ThemeName => {
    const storageValue = this.getStorage()
    if (storageValue) {
      return storageValue
    }

    return window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light"
  }

  /** Toggles the current theme between light & dark */
  toggle = () => {
    this.setStorage(this.get() === "dark" ? "light" : "dark")
  }

  /** Subscribe to theme changes */
  subscribe = (callback: typeof this.subscriberCallback) => {
    this.prefersDarkThemeMq!.addEventListener(
      "change",
      this.prefersDarkThemeMqChangeHandler
    )

    this.subscriberCallback = callback
  }

  unsubscribe = () => {
    this.prefersDarkThemeMq!.removeEventListener(
      "change",
      this.prefersDarkThemeMqChangeHandler
    )
  }
}
