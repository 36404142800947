import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Footer, Navigation, ThemeToggle } from "../components"
import { ToastContainer } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"

/**
 * Wraps every page in a layout.
 */

const Layout: React.FC<{ children?: React.ReactNode }> = ({
  children,
  ...props
}) => {
  const [active, toggleActive] = useState(false)
  const data = useStaticQuery(query)
  const pages = data.navigation.siteMetadata.pages as string

  const [windowWidth, setWindowWidth] = useState<number>(0)

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", onResize)

    onResize()

    return () => window.removeEventListener("resize", onResize)
  }, [])
  return (
    <>
      <ThemeToggle isFixed isHidden={windowWidth < 1530} />
      <LayoutWrapper id="layout" {...props}>
        <Navigation
          active={active}
          toggleActive={toggleActive}
          pages={JSON.parse(pages)}
        />
        <ContentWrapper>{children}</ContentWrapper>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </LayoutWrapper>
      <ToastContainer />
    </>
  )
}

export default Layout

const query = graphql`
  query NaviagtionQuery {
    navigation: site {
      siteMetadata {
        title
        pages
      }
    }
  }
`

const LayoutWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${props => props.theme.palette.backgroundColor};
  align-items: center;
`

const ContentWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 88px;

  > * {
    width: 100%;
  }
`
const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 100%;
`
