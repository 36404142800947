import Layout from "./src/components/Layout/Layout"
import GlobalStyle from "./src/styles/globalstyles"
import { ThemeProvider } from "./src/styles/themes"
import React from "react"

import { GatsbyBrowser } from "gatsby"

import "simplebar/dist/simplebar.min.css"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <ThemeProvider>{element}</ThemeProvider>
}

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return (
    <>
      <GlobalStyle />
      <Layout>{element as any}</Layout>
    </>
  )
}

export const onInitialClientRender = () => {
  document.getElementById("___loader")!.style.display = "block"
  setTimeout(function () {
    document.getElementById("___loader")!.style.display = "none"
  }, 200)
}
