import React, { FunctionComponent } from "react"
import styled from "styled-components"

const IconContainer: FunctionComponent<IconContainerProps> = ({ children }) => {
  return (
    <Wrapper>
      <OuterCircle>{children}</OuterCircle>
    </Wrapper>
  )
}

type IconContainerProps = {
  margin?: string
}

export default IconContainer

const OuterCircle = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.palette.backgroundColor};
`

const Wrapper = styled.div<any>`
  position: absolute;
  top: -40px;
  display: flex;
  justify-content: center;
`
