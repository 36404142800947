import React, { FC } from "react"
import Lottie from "../Lottie/Lottie"
//@ts-ignore
import loader from "../../assets/lottie/loader.json"
import styled from "styled-components"
const Loader: FC<LoaderProps> = () => {
  return (
    <LoaderWrapper>
      <Lottie width={100} height={100} lottie={loader} loop></Lottie>
    </LoaderWrapper>
  )
}

type LoaderProps = {}

export default Loader

const LoaderWrapper = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
