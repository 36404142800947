import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
//@ts-ignore
import VerticalLogo from "../../assets/svg/logo/Vertical_white.svg"
import { ButtonLink, Container } from "../components"
import Burgermenu from "./Burgermenu"
import { Pages } from "../../../gatsbyData"

interface Props {
  active: boolean
  toggleActive: (active: boolean) => void
  pages: Pages
}

const Navigation = ({ active, toggleActive, pages }: Props) => {
  const pageEntries = Object.entries(pages)

  const navigationPages = pageEntries
    .filter(page => page[1].navigation?.isInMainNavigation)
    .sort(a => Number(!!a[1].navigation))

  return (
    <NavigationWrapper>
      <Container
        style={{
          position: "relative",
          zIndex: 999,
          justifyContent: "center",
          height: "100%",
          padding: "0 16px",
        }}
      >
        <FlexContainer>
          <LogoWrapper to="/" aria-label="startseite">
            <VerticalLogo alt="Logo der Incoqnito GmbH" height="48px" />
          </LogoWrapper>
          <NavigationList id="navigation">
            {navigationPages.map((page, i: number) => {
              const [path, pageData] = page

              if (pageData.navigation!.isCTA) {
                return (
                  <NavigationEntry key={i}>
                    <ButtonLink cta to={path as any}>
                      {pageData.navigation!.title}
                    </ButtonLink>
                  </NavigationEntry>
                )
              }

              return (
                <NavigationEntry key={i}>
                  <NavigationLink
                    activeClassName="active"
                    partiallyActive={i === 0 ? false : true}
                    to={path}
                  >
                    {pageData.navigation!.title}
                  </NavigationLink>
                </NavigationEntry>
              )
            })}
          </NavigationList>
          <Burgermenu
            active={active}
            toggleActive={toggleActive}
            navigationPages={navigationPages}
          />
        </FlexContainer>
      </Container>
    </NavigationWrapper>
  )
}

export default Navigation

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const NavigationWrapper = styled.header<{}>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 88px;
  color: white;
  transition: all 0.3s ease-in;
  background: ${props => props.theme.palette.backgroundColor};
  border-bottom: 1px solid ${props => props.theme.palette.borderColor};
  /* box-shadow: 0 4px 30px rgb(11 33 74 / 11%), 0 -2px 5px rgb(11 33 74 / 3%);
    backdrop-filter: blur(32px); */
`

const LogoWrapper = styled(Link)<{}>`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  transition: all 0.3s ease-in;
  path {
    transition: all 0.3s ease-in;
    fill: ${props => props.theme.palette.text.vibrant};
  }
`

const NavigationList = styled.ul`
  display: flex;
  width: 100%;
  flex: 0 1 100%;
  justify-content: flex-end;
  margin: 0;
  align-items: center;
  @media (max-width: 1024px) {
    & {
      display: none;
    }
  }
`

const NavigationEntry = styled.li`
  display: flex;
  padding: 0 18px;
  white-space: nowrap;
`

const NavigationLink = styled(Link)<{}>`
  display: flex;
  font-weight: 600;
  align-items: center;
  text-decoration: none;
  font-size: 0.875rem;
  color: ${props => props.theme.palette.text.vibrant};
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in;

  &.active {
    border-bottom: 2px solid ${props => props.theme.palette.text.vibrant};
  }
`
