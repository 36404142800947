import React, { FC, useState } from "react"
import styled, { css } from "styled-components"
import { useTheme } from "../../styles/themes"
import { Icon } from "../components"
import { motion } from "framer-motion"

type ThemeToggleProps = {
  /** Value in REM */
  height?: number
  isHidden: boolean
  isFixed?: boolean
}

const ThemeToggle: FC<ThemeToggleProps> = ({
  height = 2,
  isHidden,
  isFixed,
}) => {
  const [toggled, setToggled] = useState<boolean>(false)
  const { toggleTheme } = useTheme()
  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30,
  }

  return (
    <ThemeToggleWrapper
      isFixed={!!isFixed}
      height={height}
      isHidden={isHidden}
      isToggled={toggled}
      onClick={() => {
        toggleTheme()
        setToggled(!toggled)
      }}
    >
      <Circle height={height} transition={spring} layout>
        {toggled ? <SunIcon></SunIcon> : <MoonIcon></MoonIcon>}
      </Circle>
    </ThemeToggleWrapper>
  )
}

export default ThemeToggle

const SunIcon = styled(Icon.Sun)`
  width: 1rem;
  height: 1rem;
`
const MoonIcon = styled(Icon.Moon)`
  width: 1rem;
  height: 1rem;
`

const ThemeToggleWrapper = styled(motion.button)<{
  isToggled: boolean
  isHidden: boolean
  height: number
  isFixed: boolean
}>`
  cursor: pointer;
  border: none;
  z-index: 40;
  border-radius: 50rem;
  padding: 0.2rem;
  border: 1px solid ${props => props.theme.palette.borderColor};
  display: ${props => (props.isHidden ? "none" : "flex")};
  justify-content: ${props => (props.isToggled ? "flex-end" : "flex-start")};
  box-shadow: inset 0 0 4px 0 rgb(0 0 0 / 5%);
  background: ${props => props.theme.palette.foregroundColor};
  height: ${p => p.height}rem;
  width: ${p => p.height * 2}rem;
  ${props =>
    props.isFixed &&
    css`
      position: fixed;
      top: 24px;
      right: 40px;
    `}
`

const Circle = styled(motion.div)<{
  height: number
}>`
  height: ${p => p.height * 0.75}rem;
  width: ${p => p.height * 0.75}rem;
  padding: 0.2rem;
  border-radius: 50rem;
  background: ${props => props.theme.palette.backgroundColor};
  border: 1px solid ${props => props.theme.palette.borderColor};
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 15%);
  display: flex;
  align-items: center;
  justify-content: center;
  path {
    fill: ${props => props.theme.palette.blue.vibrant};
  }
`
