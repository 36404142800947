import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

interface Props {
  path: string
}

const Hyperlink: React.FC<Props> = ({ children, path }) => {
  return <ButtonWrapper to={path}>{children}</ButtonWrapper>
}

const ButtonWrapper = styled(Link)`
  line-height: 1;
  color: ${props => props.color};
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  align-self: flex-start;
  outline: 0;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  &:hover {
    border-bottom: #090e24;
  }
`

export default Hyperlink
