import React from "react"
import styled from "styled-components"
import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useTheme } from "../../styles/themes"

interface Props {
  children: React.ReactNode[]
}

const Slider: React.FC<Props> = ({ children }) => {
  const { currentBreakpoint } = useTheme()

  const [paginationEl, setPaginationEl] = React.useState<HTMLDivElement | null>(
    null
  )

  const getSlidesPerView = () => {
    const maxAmount = 3
    let amount = 3

    switch (currentBreakpoint.name) {
      case "xxl":
        amount = maxAmount
        break
      case "xl":
      case "lg":
        amount = 2
        break
      case "md":
      case "sm":
        amount = 1
        break
    }

    if (amount > children.length && children.length < maxAmount) {
      amount = children.length
    }

    return amount
  }

  return (
    <SliderWrapper>
      <Swiper
        modules={[Pagination]}
        slidesPerView={getSlidesPerView()}
        spaceBetween={32}
        pagination={{ clickable: true, el: paginationEl }}
      >
        {children.map((node, i) => {
          return (
            <SwiperSlide key={i} style={{ height: "auto" }}>
              <SlideWrapper>{node}</SlideWrapper>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <SwiperPagination ref={setPaginationEl} />
    </SliderWrapper>
  )
}

const SliderWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const SlideWrapper = styled.div`
  height: 100%;
  width: 100%;

  > * {
    width: 100%;
    height: 100%;
  }
`

const SwiperPagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
`

export default Slider
