import React from "react"
import styled from "styled-components"
import { Column, Container, Heading, Icon, Row, Spacer } from "../components"
import FlexComponent from "../Flex/Flex"

const Benefits = () => {
  return (
    <BefenitsWrapper>
      <Container>
        <Row>
          <Column style={{ paddingBottom: "24px" }}>
            <Heading tag="h1">Was wir dir bieten</Heading>
          </Column>
        </Row>
        <Spacer gutter={80} />

        <Flex>
          {benefits.map(e => {
            return (
              <BenefitItem>
                <IconWrapper background={e.background}>
                  {e.icon(e.fill)}
                </IconWrapper>
                {e.icon}
                <Heading tag="h4">{e.title}</Heading>
              </BenefitItem>
            )
          })}
        </Flex>
      </Container>
    </BefenitsWrapper>
  )
}

export default Benefits

const Flex = styled(FlexComponent)`
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;

  > * {
    width: 30%;
    min-width: 300px;

    ${p => p.theme.breakpoints.sm} {
      width: 100%;
    }
  }
`

type Benefit = {
  icon: (fill: string) => JSX.Element
  title: string
  description: string
  background: string
  fill: string
}

const BefenitsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 80px 0;
  align-items: center;
  justify-content: center;
`

const benefits: Benefit[] = [
  {
    icon: fill => <WineGlassIcon color={fill} />,
    title: "Gemeinsame Events und Teamabende",
    description: "",
    background: "#fff",
    fill: "#22A7F0",
  },
  {
    icon: fill => <SnacksIcon color={fill} />,
    title: "Kostenlose Getränke und Snacks",
    description: "",
    background: "#fff",
    fill: "#F9690E",
  },
  {
    icon: fill => <ElderIcon color={fill} />,
    title: "Betriebliche Altersvorsorge",
    description: "",
    background: "#fff",
    fill: "#9B59B6",
  },
  {
    icon: fill => <CardIcon color={fill} />,
    title: "Jobcards und Kreditkarten für Mitarbeiter",
    description: "",
    background: "#fff",
    fill: "#F64747",
  },
  {
    icon: fill => <MedicIcon color={fill} />,
    title: "Betriebliche Gesundheitsleistungen",
    description: "",
    background: "#fff",
    fill: "#03C9A9",
  },
  {
    icon: fill => <ProjectIcon color={fill} />,
    title: "Spannende Projekte und neue Technologien",
    description: "",
    background: "#fff",
    fill: "#f53b57",
  },
  {
    icon: fill => <CareerIcon color={fill} />,
    title: "Langfristige Karrieremöglichkeiten",
    description: "",
    background: "#fff",
    fill: "#3c40c6",
  },
  {
    icon: fill => <HomeOfficeIcon color={fill} />,
    title: "Flexible Arbeitszeiten und Home Office",
    description: "",
    background: "#fff",
    fill: "#ffa801",
  },
  {
    icon: fill => <OpenCultureIcon color={fill} />,
    title: "Offene Unternehmenskultur",
    description: "",
    background: "#fff",
    fill: "#575fcf",
  },
]

const IconWrapper = styled.div<any>`
  position: absolute;
  top: -40px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.foregroundColor};
  /* box-shadow: 0 4px 30px rgb(11 33 74 / 11%), 0 -2px 5px rgb(11 33 74 / 3%); */
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
`

const SnacksIcon = styled(Icon.Snacks)<{ color: string }>`
  fill: ${props => props.color};
  width: 32px;
`
const MedicIcon = styled(Icon.Medic)<{ color: string }>`
  fill: ${props => props.color};
  width: 32px;
`
const WineGlassIcon = styled(Icon.WineGlass)<{ color: string }>`
  fill: ${props => props.color};
  width: 32px;
`
const CardIcon = styled(Icon.Card)<{ color: string }>`
  fill: ${props => props.color};
  width: 32px;
`
const CareerIcon = styled(Icon.Career)<{ color: string }>`
  fill: ${props => props.color};
  width: 32px;
`
const ElderIcon = styled(Icon.Elder)<{ color: string }>`
  fill: ${props => props.color};
  width: 32px;
`
const ProjectIcon = styled(Icon.Project)<{ color: string }>`
  fill: ${props => props.color};
  width: 32px;
`
const HomeOfficeIcon = styled(Icon.HomeOffice)<{ color: string }>`
  fill: ${props => props.color};
  width: 32px;
`
const OpenCultureIcon = styled(Icon.OpenCulture)<{ color: string }>`
  fill: ${props => props.color};
  width: 32px;
`
const BenefitItem = styled.div`
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  position: relative;
  text-align: center;
  /* margin: 16px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0 1 auto;
  height: 200px;
  padding: 40px;
  flex-wrap: wrap;
  /* box-shadow: 0 4px 30px rgb(11 33 74 / 11%), 0 -2px 5px rgb(11 33 74 / 3%); */
  background: ${props => props.theme.palette.backgroundColor};
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 2rem;
  h4 {
    padding: 0;
    font-weight: 400;
    word-break: normal;
  }

  &:hover {
    transform: translateY(-20px);
    ${IconWrapper} {
      top: -30px;
    }
  }
`
