import React, { FC } from "react"
import styled, { css } from "styled-components"
import { Link, GatsbyLinkProps, graphql, useStaticQuery } from "gatsby"
import { Icon } from "../components"
import { PagePaths, SiteMetadata } from "../../../gatsbyData"

interface ButtonLinkProps extends GatsbyLinkProps<{}> {
  isBlock?: boolean
  inverted?: boolean
  arrowButton?: boolean
  red?: boolean
  purple?: boolean
  blue?: boolean
  cta?: boolean
  htmlLinkHref?: string
  to: PagePaths
}

const ButtonLink: FC<ButtonLinkProps> = ({
  children,
  inverted,
  isBlock,
  arrowButton,
  red,
  blue,
  purple,
  to,
  htmlLinkHref,
  style,
  cta,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            pages
          }
        }
      }
    `
  )

  const siteMetadata: SiteMetadata = site.siteMetadata

  if (typeof siteMetadata.pages === "string") {
    siteMetadata.pages = JSON.parse(siteMetadata.pages as unknown as string)
  }

  if (htmlLinkHref) {
    const title = siteMetadata.pages[to].title

    return (
      <LinkButton style={style} href={htmlLinkHref} title={title}>
        {children}
      </LinkButton>
    )
  }

  return (
    <>
      {!arrowButton ? (
        <ButtonWrapper
          isCta={cta}
          to={to}
          isInverted={inverted}
          style={{
            width: isBlock ? "100%" : "auto",
            display: "inline-block",
          }}
        >
          {children}
        </ButtonWrapper>
      ) : (
        <ArrowButton red={red} blue={blue} purple={purple} to={to}>
          {children}
          <IconWrapper>
            <ArrowIcon red={red} blue={blue} purple={purple} height={20} />
          </IconWrapper>
        </ArrowButton>
      )}
    </>
  )
}

const ArrowIcon = styled(Icon.ArrowRight)`
  path {
    ${props =>
      props.red &&
      css`
        fill: ${p => p.theme.palette.red.vibrant};
      `}
    ${props =>
      props.blue &&
      css`
        fill: ${p => p.theme.palette.blue.vibrant};
      `}
  ${props =>
      props.purple &&
      css`
        fill: ${p => p.theme.palette.purple.vibrant};
      `}
  }
`

const IconWrapper = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease-in-out;
`

const ArrowButton = styled(Link)<any>`
  background: transparent;
  line-height: 1;
  color: white;
  font-weight: 500;
  font-size: 1.125rem;
  text-decoration: none;
  align-self: flex-start;
  text-align: center;
  padding: 0px;
  outline: 0;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  display: flex;
  align-items: center;
  ${props =>
    props.red &&
    css`
      color: ${p => p.theme.palette.red.vibrant};
    `}
  ${props =>
    props.blue &&
    css`
      color: ${p => p.theme.palette.blue.vibrant};
    `}
  ${props =>
    props.purple &&
    css`
      color: ${p => p.theme.palette.purple.vibrant};
    `}

  &:hover {
    ${IconWrapper} {
      padding-left: 24px;
    }
  }
`

const isCta = css`
  background: ${props => props.theme.palette.cta};
  border: ${props => `2px solid  ${props.theme.palette.cta}`};
`

const ButtonWrapper = styled(Link)<{ isCta?: boolean; isInverted?: boolean }>`
  border-radius: 50px;
  background: ${props => props.theme.palette.blue.vibrant};
  border: ${props =>
    props.isInverted
      ? "2px solid white"
      : `2px solid  ${props.theme.palette.blue.vibrant}`};
  line-height: 1;
  color: white;
  font-weight: 600;
  font-size: 0.875rem;
  text-decoration: none;
  align-self: flex-start;
  text-align: center;
  padding: 16px 40px;
  outline: 0;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  &:hover {
    background: ${props => (props.isInverted ? "white" : "#323f4c")};
    color: ${props => (props.isInverted ? "white" : "")};
    border: ${props =>
      props.isInverted ? "2px solid white" : "2px solid #323f4c"};
  }
  ${props => props.isCta && isCta}
`

const LinkButton = styled.a<{
  isCta?: boolean
  isInverted?: boolean
  title: string
}>`
  border-radius: 50px;
  background: ${props => props.theme.palette.blue.vibrant};
  border: ${props =>
    props.isInverted
      ? "2px solid white"
      : `2px solid  ${props.theme.palette.blue.vibrant}`};
  line-height: 1;
  color: white;
  font-weight: 600;
  font-size: 0.875rem;
  text-decoration: none;
  align-self: flex-start;
  text-align: center;
  padding: 16px 40px;
  outline: 0;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  &:hover {
    background: ${props => (props.isInverted ? "white" : "#323f4c")};
    color: ${props => (props.isInverted ? "white" : "")};
    border: ${props =>
      props.isInverted ? "2px solid white" : "2px solid #323f4c"};
  }
  ${props => props.isCta && isCta}
`

export default ButtonLink
