import styled from "styled-components"

const Subtitle = styled.span<{
  color: "red" | "blue" | "yellow" | "purple"
}>`
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 8px;
  text-transform: uppercase;
  color: ${props =>
    (props.color === "red" && props.theme.palette.red.vibrant) ||
    (props.color === "blue" && props.theme.palette.blue.vibrant) ||
    (props.color === "purple" && props.theme.palette.purple.vibrant) ||
    (props.color === "yellow" && props.theme.palette.yellow.vibrant)};
`

export default Subtitle
