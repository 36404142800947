import React, { FunctionComponent } from "react"
import styled from "styled-components"

const Section: FunctionComponent<SectionProps> = ({
  children,
  backgroundColor,
  partially,
}) => {
  return (
    <SectionWrapper backgroundColor={backgroundColor} partially={partially}>
      <SectionContent>{children}</SectionContent>
    </SectionWrapper>
  )
}

type SectionProps = {
  fullHeight?: boolean
  partially?: boolean
  primary?: boolean
  backgroundColor?: string
}

export default Section

const SectionWrapper = styled.section<any>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background-size: cover;
  background-position: center top;
  width: 100%;
  padding: 1rem 0px;
  border-bottom: 1px solid ${props => props.theme.palette.borderColor};
  background-color: ${props =>
    props.backgroundColor || props.theme.palette.foregroundColor};
  top: ${props => (props.partially ? "-400px" : "0")};
  margin-bottom: ${props => (props.partially ? "-400px" : "0")};
`

const SectionContent = styled.div<any>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  align-items: center;
`
