import styled from "styled-components"

const Input = styled.input`
  font-weight: 400;
  background: ${props => props.theme.palette.foregroundColor};
  border: 0;
  border-radius: 2px;
  height: 60px;
  padding: 0 32px;
  outline: none;
  width: 100%;
  color: ${props => props.theme.palette.text.onForeground};
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #999;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #999;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #999;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: #999;
  }
`

export default Input
