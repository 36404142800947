import { motion } from "framer-motion"
import React, { FC, useEffect } from "react"
import styled from "styled-components"

const InitialTransition: FC<InitialTransitionProps> = () => {
  const easing = [0.87, 0, 0.13, 1]
  // Scroll user to top to avoid showing the footer
  useEffect(() => {
    typeof window !== "undefined" && window.scrollTo(0, 0)
  }, [])

  const blackBox = {
    initial: {
      height: "100vh",
      bottom: 0,
    },
    animate: {
      height: 0,
      transition: {
        when: "afterChildren",
        duration: 1.5,
        ease: easing,
      },
    },
  }

  const textContainer = {
    initial: {
      opacity: 1,
    },
    animate: {
      opacity: 0,
      transition: {
        duration: 1,
        when: "afterChildren",
      },
    },
  }

  const logo = {
    initial: {
      y: -40,
    },
    animate: {
      y: 0,
      transition: {
        duration: 1.5,
        ease: easing,
      },
    },
  }

  const HandleAnimationStart = () => {
    document.body.classList.add("overflow-hidden")
  }
  const HandleAnimationEnd = () => {
    document.body.classList.remove("overflow-hidden")
    const logo = document.getElementById("initialAnimationLogo")
    logo?.classList.add("display-none")
  }

  return (
    <TransitionContainer>
      <BlackBox
        initial="initial"
        animate="animate"
        variants={blackBox}
        onAnimationStart={HandleAnimationStart}
        onAnimationComplete={HandleAnimationEnd}
      >
        <Container variants={textContainer}>
          <Logo id="initialAnimationLogo" variants={logo}>
            <svg
              version="1.1"
              id="Ebene_1"
              x="0px"
              y="0px"
              viewBox="0 0 256.7 295"
            >
              <g id="Logo_x5F_blue">
                <path
                  className="logoLine"
                  d="M124.6,10.9L11,76.5c-2,1.2-3.3,3.3-3.3,5.7v131.2c0,2.4,1.3,4.5,3.3,5.7l113.6,65.6c2,1.2,4.5,1.2,6.6,0
		l113.6-65.6c2-1.2,3.3-3.3,3.3-5.7V82.2c0-2.4-1.2-4.5-3.3-5.7L131.2,10.9C129.1,9.7,126.6,9.7,124.6,10.9z M231.7,211.5l-100.5,58
		c-2,1.2-4.5,1.2-6.6,0l-100.5-58c-2-1.2-3.3-3.3-3.3-5.7v-36.5c0-2.4,1.2-4.5,3.3-5.7l42.5-24.5c4.4-2.5,9.8,0.6,9.8,5.7v28.9
		c0,2.4,1.2,4.5,3.3,5.7l44.6,25.8c2,1.2,4.5,1.2,6.6,0l1.3-0.7l2.8-1.6l90.1-51.9c4.4-2.5,9.8,0.6,9.8,5.7v49.2
		C235,208.1,233.7,210.3,231.7,211.5z M89.6,166.1v-36.4c0-2.3,1.2-4.5,3.3-5.7l31.5-18.2c2-1.2,4.5-1.2,6.6,0l31.5,18.2
		c2,1.2,3.3,3.3,3.3,5.7v36.4c0,2.4-1.2,4.5-3.3,5.7L131,190c-2,1.2-4.5,1.2-6.6,0l-31.5-18.2C90.9,170.6,89.6,168.4,89.6,166.1z
		 M231.7,132l-43,24.7c-4.4,2.5-9.8-0.6-9.8-5.7v-28.9c0-2.4-1.2-4.5-3.3-5.7L131,90.7c-2-1.2-4.5-1.2-6.6,0l0,0l-93.7,54
		c-4.4,2.5-9.8-0.6-9.8-5.7V89.8c0-2.4,1.2-4.5,3.3-5.7l100.5-58c2-1.2,4.5-1.2,6.6,0l100.5,58c2,1.2,3.3,3.3,3.3,5.7v36.6
		C235,128.7,233.7,130.8,231.7,132z"
                />
              </g>
            </svg>
          </Logo>
        </Container>
      </BlackBox>
    </TransitionContainer>
  )
}

const Logo = styled(motion.div)`
  .logoLine {
    fill: #fff;
  }

  svg {
    width: 75px;
  }
`

const Container = styled(motion.div)``

const TransitionContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
const BlackBox = styled(motion.div)`
  position: relative;
  z-index: 50000;
  width: 100%;
  background-color: #090e24;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

type InitialTransitionProps = {}

export default InitialTransition
