import { motion } from "framer-motion"
import React, { useState } from "react"
import styled from "styled-components"
import Icon from "../Icon/Icon"

const Collapsible: React.FC<{ title: string; entries: any[] }> = ({
  entries,
  title,
}) => {
  const [active, setActive] = useState(0)
  return (
    <CollapsibleWrapper>
      <CollapsibleHeader>{title}</CollapsibleHeader>
      <CollapsibleBody
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
      >
        {entries.map((e, i) => {
          return (
            <CollapsibleItem
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              // exit={{ opacity: 0 }}
              transition={{ ease: "easeInOut", duration: 0.3 }}
              layout
            >
              <CollapsibleTitle
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                layout="position"
                layoutId={`title-${e.title}`}
                onClick={() => setActive(i)}
              >
                <span>{e.title}</span>
                {active === i ? (
                  <Icon.Minus color="white" height={20} />
                ) : (
                  <Icon.Plus color="white" height={20} />
                )}
              </CollapsibleTitle>
              {active === i ? (
                <CollapsibleContent
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  // exit={{ opacity: 0 }}
                  transition={{ ease: "easeInOut", duration: 0.3 }}
                  layout="position"
                >
                  {typeof e.content === "function" ? e.content() : e.content}
                </CollapsibleContent>
              ) : null}
            </CollapsibleItem>
          )
        })}
      </CollapsibleBody>
    </CollapsibleWrapper>
  )
}

export default Collapsible

const CollapsibleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px;
`
const CollapsibleItem = styled(motion.li)`
  padding-bottom: 24px;
  list-style: none;
`

const CollapsibleHeader = styled.h2`
  padding-bottom: 120px;
  font-size: 5rem;
  font-weight: 400;
  color: white;
  flex: 0 0 auto;
`

const CollapsibleBody = styled(motion.ul)`
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
`

const CollapsibleTitle = styled(motion.div)`
  display: flex;
  color: white;
  border-bottom: 2px solid white;
  font-size: 1.5rem;
  font-weight: 400;
  transition: all 0.3s ease-in;
  cursor: pointer;
  span {
    flex: 0 1 100%;
  }

  path {
    fill: white;
  }
`

const CollapsibleContent = styled(motion.div)`
  display: flex;
  color: white;
  font-size: 1.25rem;
  padding: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  span {
    flex: 0 1 100%;
  }

  path {
    fill: white;
  }
`
