import styled, { css } from "styled-components"

const Paragraph = styled.p<{
  isOnForeground?: boolean
  bold?: boolean
  inverted?: boolean
  textAlign?: string
  maxWidth?: string
}>`
  text-align: ${props => props.textAlign || "left"};
  font-size: 1.125rem;
  margin: 0;
  max-width: ${props => props.maxWidth || "700px"};
  line-height: 1.7;
  color: ${props =>
    props.isOnForeground
      ? `${props.theme.palette.text.onForeground}`
      : `${props.theme.palette.text.light}`};
  padding-bottom: 24px;
  font-weight: ${props => (props.bold ? 600 : 400)};
  font-weight: 400;
  word-break: break-word;
  ${props =>
    props.inverted &&
    css`
      color: white;
    `}
`

export default Paragraph
