import React, { useEffect, useMemo } from "react"
import { useState } from "react"
import { DefaultTheme } from "styled-components"

import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components"
import { ThemeName, ThemePreference } from "./utils"

/** media queries by Tailwind https://tailwindcss.com/docs/responsive-design#customizing-breakpoints */
const breakpoints = {
  sm: "@media only screen and (max-width: 640px)",
  md: "@media only screen and (max-width: 768px)",
  lg: "@media only screen and (max-width:	1024px)",
  xl: "@media only screen and (max-width: 1280px)",
  xxl: "@media only screen and (max-width: 1536px)",
}

declare module "styled-components" {
  export interface DefaultTheme {
    breakpoints: typeof breakpoints
  }
}

export const lightTheme: DefaultTheme = {
  breakpoints,
  borderRadius: "8px",
  palette: {
    backgroundColor: "#ffffff",
    foregroundColor: "#F8F9FB",
    borderColor: "#efefef",
    cta: "#F96C6E",
    purple: {
      vibrant: "#6245E9",
      light: "#F2F1FF",
    },
    blue: {
      vibrant: "#2188F9",
      light: "#EFF6FF",
    },
    red: {
      vibrant: "#F5487F",
      light: "#FEEFF4",
    },
    yellow: {
      vibrant: "#FA9E2A",
      light: "#fdefd8",
    },
    text: {
      vibrant: "#090E24",
      light: "#2C3041",
      white: "#ffffff",
      onForeground: "#626E99",
    },
    footer: {
      title: "#616e99",
      text: "#232d51",
    },
  },
}

export const darkTheme: DefaultTheme = {
  ...lightTheme,
  palette: {
    backgroundColor: "#18191C",
    foregroundColor: "#1E2023",
    borderColor: "#333",
    cta: "#F96C6E",
    purple: {
      vibrant: "#7d33ff",
      light: "#3b2f76",
    },
    blue: {
      vibrant: "#2188F9",
      light: "#243c5b",
    },
    red: {
      vibrant: "#F5487F",
      light: "#943c5c",
    },
    yellow: {
      vibrant: "#FA9E2A",
      light: "#584424",
    },
    text: {
      vibrant: "#F8F9FB",
      light: "#B7B7B7",
      white: "#ffffff",
      onForeground: "#B7B7B7",
    },
    footer: {
      title: "#ffffff",
      text: "#C6C6C6",
    },
  },
}

interface ThemeProviderValue {
  currentBreakpoint: {
    name: "sm" | "md" | "lg" | "xl" | "xxl" | "outOfScale"
    value: number
  }
  toggleTheme: () => void
  currentThemeName: "dark" | "light"
  currentTheme: DefaultTheme
}

const ThemeContext = React.createContext<ThemeProviderValue>({} as any)

const themePreference = new ThemePreference()

export const ThemeProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const [currentThemeName, setCurrentThemeName] = useState<ThemeName>("light")

  const getBreakpoint = () => {
    if (typeof window === "undefined") {
      return null
    }

    const width = window.innerWidth
    windowWidth = width

    if (width <= 640) {
      return "sm"
    } else if (width <= 768) {
      return "md"
    } else if (width <= 1024) {
      return "lg"
    } else if (width <= 1280) {
      return "xl"
    } else if (width <= 1536) {
      return "xxl"
    }

    return "outOfScale"
  }

  let windowWidth: number = 0

  const [currentBreakpoint, setCurrentBreakpoint] = useState<
    ThemeProviderValue["currentBreakpoint"]["name"]
  >(getBreakpoint() || "sm")

  useEffect(() => {
    setCurrentThemeName(themePreference.get())
    themePreference.subscribe(setCurrentThemeName)

    const resizeHandler = () => {
      setCurrentBreakpoint(getBreakpoint()!)
    }

    resizeHandler()

    window.addEventListener("resize", resizeHandler)

    return () => {
      themePreference.unsubscribe()
      window.removeEventListener("resize", resizeHandler)
    }
  }, [])

  const value = useMemo(() => {
    return {
      currentBreakpoint: {
        name: currentBreakpoint,
        value: windowWidth,
      },
      toggleTheme: themePreference.toggle,
      currentThemeName,
      currentTheme: currentThemeName === "dark" ? darkTheme : lightTheme,
    }
  }, [currentThemeName, currentBreakpoint])

  return (
    <ThemeContext.Provider value={value}>
      <StyledComponentsThemeProvider theme={value.currentTheme}>
        {children}
      </StyledComponentsThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => React.useContext(ThemeContext)
