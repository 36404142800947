import { AnimatePresence, motion } from "framer-motion"
import { Link } from "gatsby"
import React, { FC } from "react"
import styled, { css } from "styled-components"
import { Page } from "../../../gatsbyData"
import { useTheme } from "../../styles/themes"
import { ButtonLink } from "../components"
import { ThemeToggle } from "../components"
import { H1Raw } from "../Heading/Heading"

type BurgermenuProps = {
  active: boolean
  toggleActive: (active: boolean) => void
  navigationPages: [string, Page][]
}

const containerVariants = {
  open: {
    width: "100%",
    transition: {
      duration: 0.3,
      ease: "easeIn",
      when: "beforeChildren",
    },
  },
  closed: {
    width: "0%",
    transition: {
      duration: 0.3,
      ease: "easeOut",
      // when: "afterChildren",
    },
  },
}

const variants = {
  open: {
    transition: { delay: 1, staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { delay: 1, staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const ItemVariants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      ease: "easeInOut",
    },
  },
  closed: {
    x: -70,
    opacity: 0,
    transition: {
      ease: "easeInOut",
    },
  },
}

const Burgermenu: FC<BurgermenuProps> = ({
  active,
  toggleActive,
  navigationPages,
}) => {
  const { currentBreakpoint } = useTheme()

  if (currentBreakpoint.value > 1024) {
    toggleActive(false)
  }

  const handleClick = () => {
    toggleActive(!active)
  }

  const activeSite =
    typeof window != "undefined"
      ? "/" + window.location.href.split("/").reverse()[0]
      : ""

  return (
    <>
      <BurgerContainer active={active} onClick={handleClick}>
        <div className={`burger burger-squeeze burger-right`}>
          <div className="burger-lines"></div>
        </div>
      </BurgerContainer>
      <AnimatePresence>
        {active && (
          <>
            <MenuOverlay
              variants={containerVariants}
              initial="closed"
              animate="open"
              exit="closed"
              active={active}
            >
              <Border />
              <AnimatePresence>
                <Menu variants={variants} active={active}>
                  {navigationPages.map((page, index: number) => {
                    const [path, pageData] = page

                    if (pageData.navigation!.isCTA) {
                      return (
                        <motion.div
                          key="button"
                          variants={ItemVariants}
                          style={{ marginTop: "1rem" }}
                        >
                          <ButtonLink
                            cta
                            onClick={handleClick}
                            to={path as any}
                          >
                            {pageData.navigation!.title}
                          </ButtonLink>
                        </motion.div>
                      )
                    }

                    return (
                      <AnimatePresence>
                        {active && (
                          <MenuEntry
                            variants={ItemVariants}
                            onClick={handleClick}
                            key={index}
                          >
                            <Link style={{ textDecoration: "none" }} to={path}>
                              <LinkText isActivePage={activeSite === path}>
                                {pageData.navigation!.title}
                              </LinkText>
                            </Link>
                          </MenuEntry>
                        )}
                      </AnimatePresence>
                    )
                  })}

                  <ThemeToggleContainer>
                    <ThemeToggle isFixed={false} isHidden={false} />
                  </ThemeToggleContainer>
                </Menu>
              </AnimatePresence>
            </MenuOverlay>
            <OutsideClick onClick={handleClick} active={active} />
          </>
        )}
      </AnimatePresence>
    </>
  )
}

export default Burgermenu

const LinkText = styled(H1Raw)<{
  isActivePage: boolean
}>`
  font-size: 1.3rem;
  text-align: "center";
  font-weight: 500;
  padding: 0;
  position: relative;
  line-height: 60px;

  ${p =>
    p.isActivePage &&
    css`
      color: ${p => p.theme.palette.cta};

      &:before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 8px 16px;
        left: -3rem;
        border-color: transparent transparent transparent
          ${p => p.theme.palette.cta};
        top: 50%;
        transform: translateY(-50%);
      }
    `}
`

const MenuEntry = styled(motion.li)`
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  list-style-type: none;
`

const ThemeToggleContainer = styled.div`
  width: 100%;
  padding: 2rem;
  padding-left: 1rem;
  display: flex;
  align-items: flex-end;
  height: 100%;
`

const Menu = styled(motion.ul)<{
  active: boolean
}>`
  text-decoration: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  padding-top: 3rem;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 100px);
`

const Border = styled.div`
  height: 88px;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.palette.borderColor};
  margin-bottom: 16px;
`

const OutsideClick = styled.div<{
  active: boolean
}>`
  transition: all 0.3s ease-in-out;
  position: fixed;
  height: 100vh;
  width: ${props => (props.active ? "100vw" : "0vw")};
  left: 0;
  top: 0;
  background: transparent;
  z-index: -2;
`

const MenuOverlay = styled(motion.div)<{
  active: boolean
}>`
  transition: all 0.3s ease-in-out;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  background: ${props => props.theme.palette.backgroundColor};
  border-right: 1px solid ${props => props.theme.palette.borderColor};
  z-index: -1;
`

const BurgerContainer = styled.div<{
  active: boolean
}>`
  display: none;

  @media (max-width: 1024px) {
    & {
      display: block;
    }

    .burger {
      height: 3em;
      width: 3em;
      position: relative;
      font-size: 12px;
      cursor: pointer;
      transition: 0.2s all;
      -webkit-tap-highlight-color: transparent;
    }
    .burger .burger-lines:after {
      left: 0;
      top: -1em;
    }
    .burger .burger-lines:before {
      left: 1em;
      top: 1em;
    }
    .burger:after {
      content: "";
      display: block;
      position: absolute;
      height: 150%;
      width: 150%;
      top: -25%;
      left: -25%;
    }
    .burger .burger-lines {
      top: 50%;
      margin-top: -0.125em;
    }
    .burger .burger-lines,
    .burger .burger-lines:after,
    .burger .burger-lines:before {
      pointer-events: none;
      display: block;
      content: "";
      width: 100%;
      border-radius: 0.25em;
      background-color: ${props => props.theme.palette.text.vibrant};
      height: 0.25em;
      position: absolute;
      transform: rotate(0);
    }
    .burger .burger-lines:after {
      left: 0;
      top: -1em;
    }
    .burger .burger-lines:before {
      left: 1em;
      top: 1em;
    }
    .burger.burger-squeeze .burger-lines,
    .burger.burger-squeeze .burger-lines:after,
    .burger.burger-squeeze .burger-lines:before {
      transition: 0.2s top 0.2s, 0.1s left, 0.2s transform,
        0.4s background-color 0.2s;
    }

    .burger.burger-squeeze .burger-lines:after,
    .burger.burger-squeeze .burger-lines:before {
      width: 2em;
    }

    ${props =>
      props.active &&
      css`
        .burger.burger-squeeze .burger-lines,
        .burger.burger-squeeze .burger-lines:after,
        .burger.burger-squeeze .burger-lines:before {
          transition: 0.2s background-color, 0.2s top, 0.2s left,
            0.2s transform 0.15s;
        }

        .burger.burger-squeeze .burger-lines {
          background-color: transparent;
        }

        .burger.burger-squeeze .burger-lines:before,
        .burger.burger-squeeze .burger-lines:after {
          left: 0.5em;
          top: 0px;
        }

        .burger.burger-squeeze .burger-lines:before {
          transform: rotate(-45deg);
        }

        .burger.burger-squeeze .burger-lines:after {
          transform: rotate(45deg);
        }
      `}
  }
`
