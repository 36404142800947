import React from "react"
import styled from "styled-components"
import { Heading } from "../components"
import Paragraph from "../Paragraph/Paragraph"
import Slider from "../Slider/Slider"

export type CardProps = {
  icon?: () => React.ReactElement
  title: string
  content: string
  color?: "red" | "blue" | "purple" | "yellow"
}

const ServiceList = ({ entries }: { entries: CardProps[] }) => {
  return (
    <ServiceListWrapper>
      <Slider>
        {entries.map(e => {
          return (
            <ServiceListBlock key={e.title} eleCount={entries.length}>
              {!!e.icon ? (
                <IconContainer color={e.color}>
                  {!!e.icon ? e.icon() : null}
                </IconContainer>
              ) : null}
              <Heading tag="h4">{e.title}</Heading>
              <Paragraph>{e.content}</Paragraph>
            </ServiceListBlock>
          )
        })}
      </Slider>
    </ServiceListWrapper>
  )
}

export default ServiceList

const ServiceListBlock = styled.div<{ eleCount: number }>`
  display: flex;
  flex-direction: column;
  padding: 32px;
  flex-wrap: wrap;
  background: ${props => props.theme.palette.foregroundColor};
  border-radius: ${props => props.theme.borderRadius};
`

const ServiceListWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 100%;
`

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props =>
    (props.color === "red" && props.theme.palette.red.light) ||
    (props.color === "blue" && props.theme.palette.blue.light) ||
    (props.color === "purple" && props.theme.palette.purple.light) ||
    (props.color === "yellow" && props.theme.palette.yellow.light)};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: ${props =>
      (props.color === "red" && props.theme.palette.red.vibrant) ||
      (props.color === "blue" && props.theme.palette.blue.vibrant) ||
      (props.color === "purple" && props.theme.palette.purple.vibrant) ||
      (props.color === "yellow" && props.theme.palette.yellow.vibrant)};
  }
`
