import React from "react"
import styled from "styled-components"

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const Flex: React.FC<Props> = ({ children, ...rest }) => {
  return <StyledFlex {...rest}>{children}</StyledFlex>
}

const StyledFlex = styled.div`
  display: flex;
`

export default Flex
