import styled, { css } from "styled-components"
import React from "react"

type Tags = "h1" | "h2" | "h3" | "h4"

const Heading: React.FC<{
  /** Which html element is used */
  tag: Tags
  /** Overwrites the element styling */
  as?: Tags
  id?: string
  style?: React.CSSProperties
  className?: string
  children: React.ReactNode
}> = props => {
  let HeadingTag: typeof H1Raw | typeof H2Raw | typeof H3Raw | typeof H4Raw

  const wantedStyle = props.as || props.tag

  switch (wantedStyle) {
    case "h1":
      HeadingTag = H1Raw
      break
    case "h2":
      HeadingTag = H2Raw
      break
    case "h3":
      HeadingTag = H3Raw
      break
    case "h4":
      HeadingTag = H4Raw
      break
  }

  return (
    <HeadingTag
      as={props.tag as any}
      id={props.id}
      style={props.style}
      className={props.className}
    >
      {props.children}
    </HeadingTag>
  )
}

const commonStyles = css`
  color: ${props => props.theme.palette.text.vibrant};
  word-break: break-word;
  margin: 0;
  padding-bottom: 16px;

  > * {
    width: 100%;
  }
`

export const H1Raw = styled.h1`
  ${commonStyles}
  font-size: 3rem;
  font-weight: 700;
  line-height: 60px;
  ${props => props.theme.breakpoints.sm} {
    font-size: 2rem;
    line-height: 40px;
  }
`

export const H2Raw = styled.h2`
  ${commonStyles}
  font-size: 2.5rem;
  font-weight: 600;
`

export const H3Raw = styled.h3`
  ${commonStyles}
  font-size: 2rem;
  font-weight: 600;
`

export const H4Raw = styled.h4`
  ${commonStyles}
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 25px;
`

export default Heading
