import { motion } from "framer-motion"
import React, { FC, ReactElement } from "react"
import styled from "styled-components"
import { PagePaths } from "../../../gatsbyData"
import { ButtonLink, Heading, Paragraph } from "../components"

export type CardProps = {
  icon?: () => ReactElement
  title: string
  description: string
  color?: "red" | "blue" | "purple"
  button?: {
    path: PagePaths
    text: string
  }
}

const Card: FC<CardProps> = ({ icon, title, description, color, button }) => {
  return (
    <CardWrapperSpacing>
      <CardWrapper data-mh={true}>
        <Content>
          {!!icon ? (
            <IconContainer color={color}>
              {!!icon ? icon() : null}
            </IconContainer>
          ) : null}
          <div>
            <Heading tag="h4">{title}</Heading>
            <Paragraph isOnForeground>{description}</Paragraph>
          </div>
          <ButtonArea>
            {button ? (
              <>
                <ButtonLink
                  red={color === "red"}
                  blue={color === "blue"}
                  purple={color === "purple"}
                  arrowButton
                  isBlock
                  to={button.path}
                >
                  {button.text}
                </ButtonLink>
              </>
            ) : null}
          </ButtonArea>
        </Content>
      </CardWrapper>
    </CardWrapperSpacing>
  )
}

export default Card

const ButtonArea = styled.div`
  margin-top: auto;
`

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props =>
    (props.color === "red" && props.theme.palette.red.light) ||
    (props.color === "blue" && props.theme.palette.blue.light) ||
    (props.color === "purple" && props.theme.palette.purple.light)};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: ${props =>
      (props.color === "red" && props.theme.palette.red.vibrant) ||
      (props.color === "blue" && props.theme.palette.blue.vibrant) ||
      (props.color === "purple" && props.theme.palette.purple.vibrant)};
  }
`

const CardWrapperSpacing = styled.div`
  ${props => props.theme.breakpoints.md} {
    padding: 1rem;
  }
`

const CardWrapper = styled(motion.div)`
  background: ${props => props.theme.palette.foregroundColor};
  border-radius: ${props => props.theme.borderRadius};
  /* box-shadow: 0 4px 30px rgb(11 33 74 / 11%), 0 -2px 5px rgb(11 33 74 / 3%); */
  display: flex;
  padding: 2.5rem;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  height: 100%;
`

const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  height: 100%;
  padding: 0px;
  position: relative;
  z-index: 2;
`
