import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import { Page, SiteMetadata } from "../../../gatsbyData"
import { Container, Icon } from "../components"

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            pages
          }
        }
      }
    `
  )

  const siteMetadata: SiteMetadata = site.siteMetadata

  if (typeof siteMetadata.pages === "string") {
    siteMetadata.pages = JSON.parse(siteMetadata.pages as unknown as string)
  }

  const pages = Object.entries(siteMetadata.pages)

  const isMenuPage = (page: typeof pages[0]) =>
    page[1].navigation?.isInMainNavigation
  const isChancesPage = (page: typeof pages[0]) =>
    page[0].startsWith("/chancen/")

  const menuPages = pages.filter(isMenuPage)
  const chancesPages = pages.filter(isChancesPage)
  const remainingPages = pages.filter(
    page => !isMenuPage(page) && !isChancesPage(page)
  )

  const servicesTextLinks: SectionColumPages = [
    [
      "/leistungen#individuelle-softwareentwicklung",
      {
        navigation: { title: "Softwareentwicklung" },
      },
    ],
    [
      "/leistungen#cloud-engineering",
      {
        navigation: { title: "Cloud Engineering" },
      },
    ],
    [
      "/leistungen#devOps",
      {
        navigation: { title: "DevOps" },
      },
    ],
    [
      "/leistungen#consulting",
      {
        navigation: { title: "Consulting" },
      },
    ],
  ]

  type SectionColumPages = [string, Pick<Page, "navigation">][]

  const SectionColumn: React.FC<{
    pages: SectionColumPages
    headingTitle: string
  }> = ({ headingTitle, pages }) => {
    return (
      <Section>
        <FooterHeadline>{headingTitle}</FooterHeadline>
        <FooterNavigation>
          {pages.map(page => {
            const [pagePath, pageData] = page

            return (
              <FooterEntry key={pagePath}>
                <FooterLink to={pagePath}>
                  {pageData.navigation?.title}
                </FooterLink>
              </FooterEntry>
            )
          })}
        </FooterNavigation>
      </Section>
    )
  }

  return (
    <FooterWrapper>
      <Container>
        <SectionColumns>
          <SectionColumn pages={menuPages} headingTitle="Menü" />
          <SectionColumn pages={chancesPages} headingTitle="Chancen" />
          <SectionColumn pages={servicesTextLinks} headingTitle="Leistungen" />
          <SectionColumn pages={remainingPages} headingTitle="Unternehmen" />
        </SectionColumns>
        <FooterEnd>
          <FooterEndText>
            <span>Incoqnito GmbH · Fössestr 77A · D-30451 Hannover</span>
            <span>
              Copyright © {new Date().getFullYear()} Incoqnito GmbH. Alle Rechte
              vorbehalten.
            </span>
          </FooterEndText>

          <SocialMediaList>
            <SocialMediaIcon type="LinkedIn" />
            <SocialMediaIcon type="Xing" />
            <SocialMediaIcon type="Facebook" />
            <SocialMediaIcon type="Instagram" />
          </SocialMediaList>
        </FooterEnd>
      </Container>
    </FooterWrapper>
  )
}

type SocialMediaTypes = "LinkedIn" | "Xing" | "Facebook" | "Instagram"

const SocialMediaIcon: React.FC<{
  type: SocialMediaTypes
}> = ({ type }) => {
  const hrefs: { [key in SocialMediaTypes]: string } = {
    LinkedIn: "https://www.linkedin.com/company/27153609",
    Xing: "https://xing.com/pages/incoqnitogmbh",
    Facebook: "https://www.facebook.com/incoqnito.io/",
    Instagram: "https://www.instagram.com/incoqnito.io/",
  }

  const SocialMediaIcon = Icon[type]

  return (
    <SocialMediaIconStyled>
      <SocialMediaLink
        aria-label={type}
        href={hrefs[type]}
        title={`Incoqnito GmbH auf ${type}`}
        target="_blank"
        rel="noopener"
      >
        <SocialMediaIcon height="32px" />
      </SocialMediaLink>
    </SocialMediaIconStyled>
  )
}

const SectionColumns = styled.div`
  display: flex;
  justify-content: space-between;

  ${p => p.theme.breakpoints.md} {
    flex-direction: column;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
`

const FooterEnd = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;

  ${p => p.theme.breakpoints.md} {
    flex-direction: column;

    > ul {
      margin-top: 1rem;
    }
  }
`

const FooterEndText = styled.p`
  display: flex;
  flex-direction: column;
  font-weight: 300;
  white-space: nowrap;
  color: ${props => props.theme.palette.footer.title};
  font-size: 0.75rem;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  text-shadow: none;
  a {
    text-decoration: underline;
    color: ${props => props.theme.palette.footer.title};
  }
`

const FooterWrapper = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 3rem 1rem;
  background: ${props => props.theme.palette.foregroundColor};
`

const FooterHeadline = styled.h4`
  font-weight: 500;
  font-size: 1rem;
  color: ${props => props.theme.palette.footer.title};
  padding-bottom: 8px;
  margin-bottom: 32px;
  text-shadow: none;
  text-transform: uppercase;

  ${p => css`
    ${p.theme.breakpoints.md} {
      margin-bottom: 0.5rem;
      margin-top: 2rem;
    }
  `}
`

const FooterNavigation = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const FooterEntry = styled.li`
  font-size: 0.75rem;
`

const FooterLink = styled(Link)`
  color: ${props => props.theme.palette.footer.text};
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  padding: 8px 0;
  text-shadow: none;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.palette.footer.text};
    text-decoration: underline;
  }
`

const SocialMediaList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;

  > :first-child {
    position: relative;
    left: -4px;
  }
`

const SocialMediaIconStyled = styled.li`
  padding-right: 0.5rem;
`

const SocialMediaLink = styled.a<{ title: string }>`
  padding: 0;
  cursor: pointer;
  path {
    fill: ${props => props.theme.palette.footer.title};
  }
`
export default Footer
