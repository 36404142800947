import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"

const BlogHeader: FunctionComponent<BlogHeaderProps> = ({
  children,
  primary,
  backgroundColor,
  partially,
  fullHeight,
}) => {
  return (
    <SectionWrapper
      backgroundColor={backgroundColor}
      primary={primary}
      fullHeight={fullHeight}
      partially={partially}
    >
      {primary ? <Image className={"primary-section"} /> : null}
      <SectionContent fullHeight={fullHeight}>{children}</SectionContent>
    </SectionWrapper>
  )
}

type BlogHeaderProps = {
  fullHeight?: boolean
  partially?: boolean
  primary?: boolean
  backgroundColor?: string
}

export default BlogHeader

const Image = styled.div`
  position: absolute;
  top: 0;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background-size: cover;
  background-position: center top;
  width: 100%;
  height: 100%;
  padding: 0;
`

const SectionWrapper = styled.section<any>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background-size: cover;
  background-position: center top;
  width: 100%;
  padding: 0;
  background-color: ${props => props.backgroundColor || "#f1f1f1"};
`

const SectionContent = styled.div<any>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  ${props =>
    props.fullHeight &&
    css`
      min-height: 100vh;
    `}
  ${props =>
    !props.fullHeight &&
    css`
      margin-top: 160px;
      margin-bottom: 160px;
    `}
`
