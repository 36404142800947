import styled from "styled-components"

const Paper = styled.div<any>`
  position: relative;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  display: flex;
  width: ${props => props.width};
  flex-direction: column;
  background: ${props => (props.inverted ? "#090E24" : "white")};
  padding: ${props => (props.padding ? props.padding : "40px")};
  border-radius: 4px;
  box-shadow: ${props =>
    props.noShadow
      ? ""
      : "0 4px 30px rgb(11 33 74 / 11%), 0 -2px 5px rgb(11 33 74 / 3%)"}; ;
`

export default Paper
