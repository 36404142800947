import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
p {
  margin: 0;
}

textarea {
  font-family: inherit;
}

* {
  scroll-behavior: smooth;
  scroll-margin-top: 2rem;
}

html,
body,
#___gatsby,
#gatsby-focus-wrapper {
  margin: 0;
  min-height: 100%;
  display: flex;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  .ch2-icon {
    right: 40px !important;
    bottom: 40px !important;
    left: auto !important;
  }
  .ch2 button {
    border-radius: 50rem !important;
  }
  .ch2-dialog {
    background: ${props => props.theme.palette.foregroundColor} !important;
  }

  .ch2-btn-primary {
    background: ${props => props.theme.palette.blue.vibrant} !important;
    &:hover {
      box-shadow: 0px 0px 20px 0 ${props =>
        props.theme.palette.blue.vibrant}66 !important;
    }
  }

  .ch2-style-light.ch2-icon a:hover svg {
    border: 2px solid  ${props => props.theme.palette.blue.vibrant} !important ;
    fill:  ${props => props.theme.palette.blue.vibrant} !important;
  }

    .ch2-settings-declaration table th {
    color: ${props => props.theme.palette.text.vibrant} !important;
  }
  .ch2-style-light .ch2-settings-content p {
      color: ${props => props.theme.palette.text.onForeground} !important;
  }

  #ch2-dialog-title strong, #ch2-settings-title strong, .ch2-header strong, #ch2-0-title, #ch2-1-title {
    color: ${props => props.theme.palette.text.vibrant} !important;
  }
  #ch2-dialog-description, #ch2-settings-description {
    color: ${props => props.theme.palette.text.onForeground} !important;
  }
  .ch2-learn-more {
      color: ${props => props.theme.palette.blue.vibrant} !important;
  }
  .ch2-settings ,.ch2-settings-actions {
     background: ${props => props.theme.palette.foregroundColor} !important;
         border-radius: ${props => props.theme.borderRadius} !important;
  }
   .ch2-switch input:checked + label{
     background: ${props => props.theme.palette.blue.vibrant} !important;
  }
  .ch2-style-light .ch2-settings-scan .ch2-settings-tabs ul li.active a {
    border: 1px solid  ${props => props.theme.palette.borderColor} !important;
    border-bottom-color: ${props => props.theme.palette.borderColor} !important;
    background: ${props => props.theme.palette.backgroundColor} !important; 
  }

html,
body {
  font-size: 16px;
}

@media (max-width: 767px) {
  html,
  body {
    font-size: 14px;
  }
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.overflow-hidden {
  overflow: hidden;
}
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}


}

// ---- Swiper.js style overwrites --

// Change background color of inactive bullet to increasae visibility in dark mode
.swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  background: gray;
}

:root {
  --toastify-toast-background: ${p => p.theme.palette.blue.vibrant} !important;
  --toastify-color-light: ${p => p.theme.palette.blue.vibrant} !important;
  --toastify-color-dark: ${p => p.theme.palette.blue.vibrant} !important;

  --toastify-text-color-light: white !important;
  --toastify-text-color-dark: white !important;
}


`

export default GlobalStyle
