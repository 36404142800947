import styled from "styled-components"

const TextArea = styled.textarea`
  font-weight: 400;
  vertical-align: top;
  background: ${props => props.theme.palette.foregroundColor};
  font-family: "Inter", "sans serif", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  border-radius: 4px;
  margin-bottom: 8px;
  border-radius: 2px;
  height: 50px;
  padding: 16px 32px;
  height: 180px;
  outline: none;
  width: 100%;
  resize: none;
  border-color: ${props => props.theme.palette.foregroundColor};
  color: ${props => props.theme.palette.text.onForeground} !important;
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    color: ${props => props.theme.palette.text.onForeground} !important;
    font-family: "Inter", "sans serif", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol";
  }
`

export default TextArea
