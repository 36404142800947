/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { FC } from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { Page, PagePaths, SiteMetadata } from "../../../gatsbyData"

type HeadProps = {
  /** Uses the data defined in gatsbyData.ts */
  pagePath?: PagePaths

  /** Overwrite the page title defined in gatsbyData.ts */
  overwrite?: Page
}

const Head: FC<HeadProps> = props => {
  const { pagePath, overwrite } = props

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            pages
          }
        }
      }
    `
  )

  if (!pagePath && !overwrite) {
    throw new Error("pagePath or overwrite should be defined as props")
  }

  const siteMetadata: SiteMetadata = site.siteMetadata

  if (typeof siteMetadata.pages === "string") {
    siteMetadata.pages = JSON.parse(siteMetadata.pages as unknown as string)
  }

  const dataSource = overwrite ? overwrite : siteMetadata.pages[pagePath!]

  return (
    <Helmet
      htmlAttributes={{
        lang: "de",
      }}
      title={dataSource.title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: dataSource.description,
        },
        {
          property: `og:title`,
          content: dataSource.title,
        },
        {
          property: `og:description`,
          content: dataSource.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: dataSource.title,
        },
        {
          name: `twitter:description`,
          content: dataSource.description,
        },
      ].concat(dataSource.metaNameTags || [])}
    />
  )
}

export default Head
