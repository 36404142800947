import React, { FunctionComponent } from "react"
import styled, { keyframes } from "styled-components"

import { Icon } from "../components"

const NextSection: FunctionComponent<{
  onClick: () => void
  inverted: boolean
  icon: string
}> = ({ onClick, inverted, icon }) => {
  const IconComponent = icon ? Icon[icon] : Icon.Chevron
  return (
    <BtnWrapper
      inverted={inverted}
      aria-label="Mehr erfahren"
      onClick={onClick}
    >
      <IconComponent height={24} />
    </BtnWrapper>
  )
}

export default NextSection

const floating = keyframes`
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
`

const BtnWrapper = styled.button<any>`
  position: absolute;
  bottom: 40px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  animation: ${floating} 3s ease-in-out infinite;
  background: ${props => (props.inverted ? "transparent" : "#090E24")};
  border: ${props =>
    props.inverted ? "1px solid #fff" : " 2px solid #090E24"};
  box-shadow: ${props =>
    props.inverted
      ? "none"
      : `0 1px 1px rgba(0,0,0,0.08), 
                0 2px 2px rgba(0,0,0,0.12), 
                0 4px 4px rgba(0,0,0,0.16), 
                0 8px 8px rgba(0,0,0,0.20)`};
  transform: 0.3s all ease-in-out;
  path {
    transform: 0.3s all ease-in-out;
    fill: ${props => (props.inverted ? "#fff" : "#fff")};
  }
  &:hover {
    color: ${props => (props.inverted ? "#fff" : "#fff")};
    background: transparent;
    border: ${props =>
      props.inverted ? "2px solid #fff" : "2px solid #090E24"};
    path {
      fill: ${props => (props.inverted ? "#fff" : "#090E24")};
    }
  }
`
