import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { Icon } from "../components"
const ImageLayer: FunctionComponent<ImageLayerProps> = ({
  children,
  image,
  inverted,
  layoutId,
  color,
  style,
  alt,
}) => {
  return (
    <ImageLayerWrapper style={style} inverted={inverted}>
      <ImageWrapper layoutId={layoutId}>
        <DecorLayer color={color} inverted={inverted} />
        <CirclesContainer color={color} inverted={inverted}>
          <DecorImage />
        </CirclesContainer>
        <BackgroundImage alt={alt} image={getImage(image)!} />
      </ImageWrapper>
      <Content inverted={inverted}>{children}</Content>
    </ImageLayerWrapper>
  )
}

type ImageLayerProps = {
  image: any
  inverted?: boolean
  layoutId?: string
  style?: React.CSSProperties
  color: "red" | "blue" | "purple"
  alt: string
}

export default ImageLayer

const DecorImage = styled(Icon.CirclesDecor)`
  width: 100%;
  height: 100%;
`

const CirclesContainer = styled.div<{
  inverted?: boolean
  color: "red" | "blue" | "purple"
}>`
  width: 200px;
  height: 200px;
  position: absolute;
  top: -32px;
  left: ${props => (props.inverted ? "unset" : "-35px")};
  right: ${props => (props.inverted ? "-35px" : "unset")};

  ${props => css`
    svg {
      fill: ${props.theme.palette[props.color].vibrant};
    }
  `}
`

// FIXME: color highlighting not working for these variables, syntax mistake somewhere?

const ImageLayerWrapper = styled(motion.div)<{ inverted?: boolean }>`
  flex: 0 1 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 60px 0;
  flex-direction: ${props => (props.inverted ? "row-reverse" : "row")};

  ${p => css`
    ${p.theme.breakpoints.lg} {
      flex-direction: column;

      > div:nth-child(1) {
        margin-bottom: 5rem;
      }
    }
  `}
`

const DecorLayer = styled.div<{
  inverted?: boolean
  color: "red" | "blue" | "purple"
}>`
  position: absolute;
  left: ${props => (props.inverted ? "20px" : "-20px")};
  bottom: -20px;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.palette[props.color].light};
  border-radius: ${props => props.theme.borderRadius};
`

const ImageWrapper = styled(motion.div)<{ inverted?: any }>`
  display: flex;
  flex: 0 1 auto;
  max-width: 500px;

  ${p => p.theme.breakpoints.md} {
    max-width: 75%;
  }

  width: 100%;
  background-blend-mode: color;
  background-size: cover;
  border-radius: ${props => props.theme.borderRadius};
  position: relative;
  margin-right: ${props => (props.inverted ? "0" : "4rem")};
  margin-left: ${props => (props.inverted ? "100px" : "0")};
  margin-left: 3rem;
`

const BackgroundImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  border-radius: ${props => props.theme.borderRadius};
`

const Content = styled.div<{
  inverted?: boolean
}>`
  flex: 0 1 100%;
  display: flex;
  padding-right: ${p => (p.inverted ? "48px" : null)};
  flex-direction: column;
  max-width: 700px;
`
